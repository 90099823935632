angular.module('CaresApp').component('ocrLogin', {
  templateUrl: 'components/ocr-login/ocr-login.template.html',
  controller: [
    '$document',
    '$location',
    '$http',
    '$mdDialog',
    '$timeout',
    'OcrAuth',
    'Idle',
    'OcrUtils',
    'UserService',
    function($document, $location, $http, $mdDialog, $timeout, OcrAuth, Idle, OcrUtils, UserService) {
      const self = this;
      OcrUtils.setPageTitle("Login")

      self.login = {};

      self.promise = undefined;

      //self.$parent.disableMenu = false;

      self.resetFocus = function() {
        $timeout(function() {
          if ($document[0].getElementById('userName')) {
            $document[0].getElementById('userName').focus();
          }
        }, 250);
      };
      self.resetFocus();

      self.loginClicked = function() {
        self.submitting = true;
        self.message = "";

        self.submitting = true;
        self.message = "";

        self.promise = $http.post("/api/login", {
          userName: self.login.userName,
          password: self.login.password,
          tfa: OcrAuth.checkSecondAuth(),
        }).then(function(response) {
          if (response.data.TFASessionId) {
            $mdDialog.show({
              controller: 'TFAAuthenticationDialogController',
              templateUrl: 'components/ocr-login/tfa-authentication-dialog.template.html',
              parent: angular.element($document.body),
              locals: {
                sessionId: response.data.TFASessionId,
                userId: response.data.userId,
              },
              bindToController: true,
              controllerAs: '$ctrl',
              clickOutsideToClose: false,
              fullscreen: self.customFullscreen,
              multiple: true,
            })
              .then(function(tfaResponse) {
                OcrAuth.setSession(response.data);
                OcrAuth.setAuthSession(tfaResponse);
                Idle.watch(); // start 30 minute idle timeout
                $location.path("/views/home");
              }, function() {});
          } else {
            OcrAuth.setSession(response.data);
            Idle.watch(); // start 30 minute idle timeout
            $location.path("/views/home");
            if((new Date().getTime() > new Date(response.data.user.activeUserRemind)) || angular.isUndefined(response.data.user.activeUserRemind)) {
              self.activeUserRemind(response.data.user);
            } else {
              self.tfaRemind(response.data.user);
            }
          }
        }).catch(function(error) {
          self.promise = undefined;
          if (error.status === 500) {
            self.message =
              "The application is currently unavailable. Please retry or contact OCR for support.";
          } else if (error.status === 423) {
            self.message =
              "Your account is disabled. Please contact OCR for support.";
          } else {
            self.message =
              "The user name or password that you entered is invalid. Please retry.";
          }
          self.resetFocus();
        }).finally(() => self.promise = undefined);
      };

      self.tfaRemind = function(user) {
        if (user.useTFA === false && (angular.isUndefined(user.tfaRemind) ||
          new Date().getTime() > new Date(user.tfaRemind).getTime())) {
          $mdDialog.show({
            controller: 'TFARemindDialogController',
            templateUrl: 'components/ocr-login/tfa-remind-dialog.template.html',
            parent: angular.element($document.body),
            locals: { user: user },
            bindToController: true,
            controllerAs: '$ctrl',
            clickOutsideToClose: false,
            fullscreen: self.customFullscreen,
            multiple: false,
          })
        }
      }

      self.activeUserRemind = function(user) {
        self.promise = UserService.query({
          office_id: user.officeId,
          active: true,
        }).$promise.then(
          function(resp) {
            if ((resp.count > 1) && user.permissions.includes('DEACTIVATE_USERS')) {
              $mdDialog.show({
                controller: 'activeUserRemindDialogController',
                templateUrl: 'components/ocr-login/active-user-remind-dialog.template.html',
                parent: angular.element($document.body),
                locals: { user: user },
                bindToController: true,
                controllerAs: '$ctrl',
                clickOutsideToClose: false,
                fullscreen: self.customFullscreen,
                multiple: false,
              })
            }}).finally(() => self.promise = null);
      }
    },
  ],
});
