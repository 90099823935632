angular.module('CaresApp').component('ocrCaseEdit', {
  templateUrl: 'components/ocr-case-edit/ocr-case-edit.template.html',
  controller: [
    '$scope',
    '$document',
    '$window',
    '$location',
    '$routeParams',
    '$mdToast',
    '$mdDialog',
    'CaseService',
    'CaseDataService',
    'OcrAuth',
    'OcrLookups',
    'OcrUtils',
    'OcrViews',
    'SessionService',
    'AppointmentOfficeAccessRequestService',
    'OcrAppointmentOfficeAccessRequestDialogService',
    function($scope, $document, $window, $location, $routeParams, $mdToast, $mdDialog,
      CaseService, CaseDataService, OcrAuth, OcrLookups, OcrUtils, OcrViews, SessionService,
      AppointmentOfficeAccessRequestService, OcrAppointmentOfficeAccessRequestDialogService,
    ) {
      const self = this;
      self.currentView = OcrViews.currentView
      self.judicialDistricts = self.caseTypes = self.counties = OcrLookups.dummyList
      OcrLookups.get('JudicialDistrict').then(
        (judicialDistricts) => self.judicialDistricts
        = judicialDistricts,
      );
      OcrLookups.get('CaseType', true).then((caseTypes) => self.caseTypes = caseTypes);
      OcrLookups.get('County').then((counties) => self.counties = counties);
      OcrLookups.get('AppointmentOfficeAccessRequestStatus').then(
        (statuses) => self.accessRequestStatuses = statuses);

      self.hasPermission = OcrAuth.checkPermission;

      self.appeals = false;

      $scope.today = OcrUtils.today


      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const removeQueryParams = (url) => {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || self.caseForm.$pristine
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to leave this page without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.caseForm.$setPristine();
            $location.path(newPath);
          });
        });

      $window.onbeforeunload = () => (self.caseForm.$pristine ? null : "Changes have been made.");

      $scope.$on("LoggingOut",
        () => {
          if (self.caseForm.$pristine) {
            SessionService.signout();
            return;
          }

          const message
            = "Are you sure that you want to leave this page without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Log Out')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(function() {
            SessionService.signout();
          });
        },
      );

      self.getLatestDate = function() {
        let d = self.today;
        if (self.entity && self.entity.appointments) {
          for (const appointment of self.entity.appointments) {
            if (new Date(appointment.startDate) < new Date(d)) {
              d = appointment.startDate;
            }
          }
        }
        return new Date(d);
      };

      self.today = OcrUtils.today;

      self.checkChange = function() {
        if(self.entity.caseTypeId != self.entity.currentCaseTypeId) {
          self.isChanged = true;
        }
      }

      self.showRequestAccessDialog = function(appointment) {
        $mdDialog.show({
          controller: 'AppointmentOfficeAccessRequestDialogController',
          templateUrl: 'dialogs/ocr-appointment-office-access-request-dialog/'
            + 'ocr-appointment-office-access-request-dialog.template.html',
          parent: angular.element($document[0].body),
          locals: {
            appointment: appointment,
            request: undefined,
            id: self.entity.abbreviatedCaseNumber,
          },
          clickOutsideToClose: false,
          bindToController: true,
          controllerAs: '$ctrl',
        }).then(function(newRequest) {
          CaseDataService.get({
            id: self.id,
          }).$promise
            .then(
              function(response) {
                self.entity = response;
                if (self.entity.dateOpened) {
                  self.entity.dateOpened = new Date(self.entity.dateOpened);
                }
                if (self.entity.dateClosed) {
                  self.entity.dateClosed = new Date(self.entity.dateClosed);
                }
                for (const appointment of self.entity.appointments) {
                  const attorney = {
                    firstName: appointment.attorneyFirstName,
                    middleName: appointment.attorneyMiddleName,
                    lastName: appointment.attorneyLastName,
                    suffix: appointment.attorneySuffix,
                  };
                  appointment.attorneyName = OcrUtils.getUserFullName(
                    attorney);
                }
              })
            .finally(() => {
              self.loading = false;
              self.latestDate = self.getLatestDate();
            });
        }, function() {});
      };

      self.cancelAccessRequest = function(appointment) {
        const message
            = "Are you sure that you want to cancel the request for access to this appointment?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Cancel Access Request')
            .textContent(message)
            .ariaLabel(message)
            .ok('Yes, cancel request')
            .cancel('Never mind'),
        ).then(() => {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Saving...')
              .position("bottom right"),
          );
          let request = appointment.accessRequests[0];
          request.statusId = 2;
          request.statusChangeDate = new Date();
          let saveFunction = AppointmentOfficeAccessRequestService.update;
          let saveArgs = [{ id: request.id }, request];
          saveFunction.apply(this, saveArgs).$promise.then(
            function() {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(`Access request cancelled.`)
                  .position("bottom right"),
              )
              appointment.accessRequests = [];
            },
          ).catch(function(error) {
            if (error && error.data && error.data.status === 401) {
              return;
            }
            if (error && error.data) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(error.data.reason)
                  .position("bottom right"),
              );
            }
          })
        }, () => {});
      };

      self.getLatestDate = function() {
        let d = self.today;
        if (self.entity && self.entity.appointments) {
          for (const appointment of self.entity.appointments) {
            if (new Date(appointment.startDate) < new Date(d)) {
              d = appointment.startDate;
            }
          }
        }
        return new Date(d);
      };

      if ($routeParams.caseId.indexOf('create') === -1) {
        self.id = $routeParams.caseId;
      } else {
        OcrUtils.setPageTitle('New Case');
      }
      self.loading = true;
      if (self.id) {
        CaseDataService.get({
          id: self.id,
        }).$promise
          .then(
            function(response) {
              self.entity = response;
              self.isChanged = false;
              OcrUtils.setPageTitle(`Case: ${self.entity.fullCaseNumber}`);
              if (self.entity.dateOpened) {
                self.entity.dateOpened = new Date(self.entity.dateOpened);
              }
              if (self.entity.dateClosed) {
                self.entity.dateClosed = new Date(self.entity.dateClosed);
              }
              for (const appointment of self.entity.appointments) {
                const attorney = {
                  firstName: appointment.attorneyFirstName,
                  middleName: appointment.attorneyMiddleName,
                  lastName: appointment.attorneyLastName,
                  suffix: appointment.attorneySuffix,
                };
                appointment.attorneyName = OcrUtils.getUserFullName(
                  attorney);
              }
            })
          .finally(() => {
            self.loading = false;
            self.latestDate = self.getLatestDate();
          });
      } else {
        self.isChanged = true;
        self.entity = {
          action: "Open",
          status: "Open",
          statusId: 1,
          statusHistory: [
            {
              date: new Date(),
              status: "Open",
            },
          ],
          appointments: [],
          dateClosed: null,
        };
        if ($location.$$search) {
          let search = false;
          if ($location.$$search.judicialDistrict) {
            self.entity.judicialDistrictId = parseInt($location.$$search.judicialDistrict);
            delete $location.$$search.judicialDistrict;
            search = true;
          } else if (OcrAuth.session.user.favoriteJudicialDistrictId !== 0) {
            self.entity.judicialDistrictId = OcrAuth.session.user.favoriteJudicialDistrictId;
          }
          if ($location.$$search.county) {
            self.entity.countyId = parseInt($location.$$search.county);
            delete $location.$$search.county;
            search = true;
          }
          if ($location.$$search.caseYear) {
            self.entity.caseYear = parseInt($location.$$search.caseYear);
            delete $location.$$search.caseYear;
            search = true;
          }
          if ($location.$$search.caseType) {
            self.entity.caseTypeId = $location.$$search.caseType;
            delete $location.$$search.caseType;
            search = true;
          }
          if ($location.$$search.caseSequence) {
            self.entity.caseSequence = $location.$$search.caseSequence;
            delete $location.$$search.caseSequence;
            search = true;
          }
          if (search) {
            $scope.$watch(
              () => angular.isDefined(self.caseForm),
              () => self.caseForm.$setDirty()
              , true);
          }
        }
        self.loading = false;
        self.latestDate = self.getLatestDate();
      }

      function isOcrStaffMember() {
        const ocrStaffMemberPersonas = [
          "OCR Staff Attorney",
          "OCR Accounting",
          "OCR Billing Manager",
          "OCR Administrative",
          "System Admin",
        ];
        return (ocrStaffMemberPersonas.indexOf(OcrAuth.session.user.persona.name) !== -1);
      }

      self.save = function(andExit) {
        if (!self.entity.fullCaseNumber || !self.entity.abbreviatedCaseNumber) {
          self.updateCaseNumber();
        }
        if (!self.entity.fullCaseNumber || !self.entity.abbreviatedCaseNumber) {
          const message = `${"There was an error generating your Case Number. "
            + "Please contact OCR (info@coloradochildrep.org) to report the "
            + "issue, including a copy of the following information: <br>"
            + "<br><br>"
            + "County ID: "}${self.entity.countyId.toString().padStart(3, "0")}${"<br>"
            + "Case Year: "}${self.entity.caseYear}${"<br>"
            + "Case Type: "}${self.caseTypes.lookup(self.entity.caseTypeId).type}${"<br>"
            + "Case Sequence: "}${self.entity.caseSequence}`;
          $mdDialog.show(
            $mdDialog.alert()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Case Number Generation Error')
              .textContent(message)
              .ariaLabel(message)
              .ok("OK"),
          );
          return;
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = CaseService.update;
          saveArgs = [
            {
              id: self.id,
            },
            self.entity,
          ];
        } else {
          saveFunction = CaseService.save;
          self.entity.dateOpened = new Date();
          saveArgs = [self.entity];
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function(resp) {
            self.entity.id = resp.id;
            self.id = resp.id;
            $mdToast.show(
              $mdToast.simple()
                .textContent('Case saved.')
                .position("bottom right"),
            );
            self.caseForm.$setPristine();
            if (andExit) {
              self.closeForm(true);
            }
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.closeForm(true);
            return;
          }
          if (error && error.data && error.data.status === 409) {
            const message = "Your case could not be created because this exact case (JD, county, " +
              "year, type, and sequence) already exists. Please use the existing case by " +
              "searching for it with the Search Cases tool. Note, the existing case may be " +
              "closed; be sure to search open and closed cases.";
            $mdDialog.show(
              $mdDialog.alert()
                .parent(angular.element($document[0].querySelector(
                  '#popupContainer')))
                .clickOutsideToClose(false)
                .title('Duplicate Case')
                .textContent(message)
                .ariaLabel(message)
                .ok("OK"),
            );
          } else if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        });
      };

      self.closeForm = function(noConfirm, canceled) {
        let message = "";

        const appWarn = function() {
          message = "Please note, this case has no appointments. To appoint your office to " +
            "this case so you can work on it, click Cancel, then click Create Appointment. Or, " +
            "to exit without taking any action on this case, click OK.";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(() => {
            self.caseForm.$setPristine();
            $location.path(`/views/${$routeParams.view}`);
          }, () => {});
        }

        const saveWarn = function() {
          message
          = "Are you sure that you want to close this form without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Form Close')
              .textContent(message)
              .ariaLabel(message)
              .ok('Close Form')
              .cancel('Cancel'),
          ).then(() => {
            if (self.entity.appointments.length === 0 && !canceled) {
              appWarn();
            } else {
              self.caseForm.$setPristine();
              $location.path(`/views/${$routeParams.view}`);
            }
          }, () => {});
        }


        if (noConfirm || self.caseForm.$pristine) {
          $location.path(`/views/${$routeParams.view}`);
        } else {
          saveWarn();
        }
      };

      self.countyChanged = function() {
        if (self.entity.countyId) {
          const county = self.counties.lookup(self.entity.countyId);
          if (county) {
            self.entity.judicialDistrictId = county.judicialDistrictId;
          }
          if (self.entity.countyId === 998) {
            self.entity.caseTypeId = 6;
            self.appeals = true;
          } else if (self.entity.countyId === 999) {
            self.entity.caseTypeId = 5;
            self.appeals = true;
          } else {
            if (self.entity.caseTypeId === 5 || self.entity.caseTypeId === 6) {
              self.entity.caseTypeId = undefined;
            }
            self.appeals = false;
          }
        }
        self.updateCaseNumber();
      };

      self.updateCaseNumber = function() {
        if (
          self.entity.countyId
          && self.entity.caseYear
          && self.entity.caseYear.toString().length === 4
          && self.entity.caseTypeId
          && self.entity.caseSequence
          && self.entity.caseSequence.length === 6
        ) {
          self.entity.fullCaseNumber = `D${self.entity.countyId.toString()
            .padStart(
              3, "0")}${self.entity.caseYear}${self.caseTypes.lookup(
            parseInt(self.entity.caseTypeId)).type}${self.entity.caseSequence}`;

          self.entity.abbreviatedCaseNumber
            = self.entity.fullCaseNumber.substring(6, 8)
            + self.caseTypes.lookup(parseInt(self.entity.caseTypeId)).type
            + (1 * (self.entity.fullCaseNumber.substring(10)));
        }
      };

      self.closeCase = function() {
        $mdDialog.show({
          controller: 'CloseCaseDialogController',
          templateUrl: 'tmpl/close-case-dialog.html',
          parent: angular.element($document[0].body),
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(function(data) {
            self.entity.action = "Close";
            self.entity.status = "Closed";
            self.entity.statusId = 2;
            self.entity.dateClosed = data.closingDate;
            self.entity.statusHistory.push({
              date: data.closingDate,
              status: "Closed",
            });

            self.save();
          }, () => {});
      };

      self.saveCreate = function() {
        if (!self.entity.fullCaseNumber || !self.entity.abbreviatedCaseNumber) {
          self.updateCaseNumber();
        }
        if (!self.entity.fullCaseNumber || !self.entity.abbreviatedCaseNumber) {
          const message = `${"There was an error generating your Case Number. "
          + "Please contact OCR (info@coloradochildrep.org) to report the "
          + "issue, including a copy of the following information: <br>"
          + "<br><br>"
          + "County ID: "}${self.entity.countyId.toString().padStart(3, "0")}${"<br>"
          + "Case Year: "}${self.entity.caseYear}${"<br>"
          + "Case Type: "}${self.caseTypes.lookup(self.entity.caseTypeId).type}${"<br>"
          + "Case Sequence: "}${self.entity.caseSequence}`;
          $mdDialog.show(
            $mdDialog.alert()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Case Number Generation Error')
              .textContent(message)
              .ariaLabel(message)
              .ok("OK"),
          );
          return;
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent('Saving...')
            .position("bottom right"),
        );
        let saveFunction = null;
        let saveArgs = null;
        if (self.id) {
          saveFunction = CaseService.update;
          saveArgs = [
            {
              id: self.id,
            },
            self.entity,
          ];
        } else {
          saveFunction = CaseService.save;
          self.entity.dateOpened = new Date();
          saveArgs = [self.entity];
        }
        saveFunction.apply(this, saveArgs).$promise.then(
          function(resp) {
            self.entity.id = resp.id;
            self.id = resp.id;
            $mdToast.show(
              $mdToast.simple()
                .textContent('Case saved.')
                .position("bottom right"),
            );
            $location.path('/appointment/my_appointments/create').search({case: self.entity.id});
            self.caseForm.$setPristine();
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 401) {
            self.closeForm(true);
            return;
          }
          if (error && error.data && error.data.status === 409) {
            const message = "Your case could not be created because this exact case (JD, county, " +
              "year, type, and sequence) already exists. Please use the existing case by " +
              "searching for it with the Search Cases tool. Note, the existing case may be " +
              "closed; be sure to search open and closed cases.";
            $mdDialog.show(
              $mdDialog.alert()
                .parent(angular.element($document[0].querySelector(
                  '#popupContainer')))
                .clickOutsideToClose(false)
                .title('Duplicate Case')
                .textContent(message)
                .ariaLabel(message)
                .ok("OK"),
            );
          } else if (error && error.data) {
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          }
        });
      };

      self.undoCloseCase = function() {
        const message = "Are you sure that you want to undo the closing of this case? This is to " +
          "undo a case closed in error.\nIf the court reopened a closed case, click cancel and " +
          "then Reopen Case.";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Undo Close Case')
            .textContent(message)
            .ariaLabel(message)
            .ok('Undo Close')
            .cancel('Cancel'),
        ).then(function() {
          self.entity.action = "Undo Close";
          self.entity.status = "Open";
          self.entity.statusId = 1;
          self.entity.dateClosed = undefined;

          self.entity.statusHistory.pop(); // remove last log entry

          self.save();
        }, () => {});
      };

      self.reopenCase = function() {
        $mdDialog.show({
          controller: 'ReopenCaseDialogController',
          templateUrl: 'tmpl/reopen-case-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            closingDate: self.dateClosed,
          },
          clickOutsideToClose: false,
          fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
        })
          .then(function(data) {
            // ok
            self.entity.action = "Reopen";
            self.entity.status = "Reopened";
            self.entity.statusId = 3;
            self.entity.dateClosed = undefined;
            self.entity.statusHistory.push({
              date: new Date(),
              status: "Reopened",
            });

            self.save();
          }, () => {});
      };

      self.checkOffice = function(appointment) {
        if (!(OcrAuth.session && OcrAuth.session.user)) {
          return false
        }
        if (OcrAuth.session.user.officeId === appointment.officeId
          || isOcrStaffMember()) {
          return true;
        }
        return false;
      };

      self.enableAppointmentButton = function(appointment) {
        if (!(OcrAuth.session && OcrAuth.session.user)) {
          return false
        }
        if (OcrAuth.session.user.officeId === appointment.officeId
          || isOcrStaffMember()) {
          return true;
        }
        return self.hasPermission('REQUEST_APPOINTMENT_ACCESS')
          && self.hasActiveAccessRequest(appointment)
          && self.accessRequestApproved(appointment);
      }

      self.disableClose = function() {
        if (self.entity) {
          if (self.loading || self.entity.statusId === 2) {
            return true;
          }
          for (const appointment of self.entity.appointments) {
            if (angular.isUndefined(appointment.endDate)) {
              return true;
            }
          }
          return false;
        }
        return false;
      };

      self.disableReopenUndo = () => self.entity && (self.loading || self.entity.statusId !== 2);

      self.disableCaseTypeEdit = function() {
        if (!self.caseTypes.length || self.appeals) {
          return true;
        } else if (self.id) {
          if (self.hasPermission('CASE_WRITE') || self.hasPermission('CASE_ALL')) {
            return false;
          }
          return true;
        }
        return false;
      };

      self.filterTypes = function(value, _, __) {
        if (self.id && self.entity) {
          if (value.type === self.caseTypes.lookup(self.entity.caseTypeId).type) {
            if (new Date(value.startDate) > new Date($scope.today)) {
              return false;
            }
            if (angular.isDefined(value.endDate)) {
              return (new Date(value.endDate) > new Date($scope.today)) || value.id === self.entity.caseTypeId;
            }
            return true;
          }
          return false;
        }
        if (new Date(value.startDate) > new Date($scope.today)) {
          return false;
        }
        if (angular.isDefined(value.endDate)) {
          return (new Date(value.endDate) > new Date($scope.today));
        }
        return true;
      };

      self.accessApproved = function(appointment) {
        if (appointment.accessRequests && appointment.accessRequests.length > 0) {
          return appointment.accessRequests[0].statusId;
        }
        return false;
      }

      self.accessRequested = function(appointment) {
        if (appointment.accessRequests && appointment.accessRequests.length > 0) {
          return appointment.accessRequests[0].statusId;
        }
        return false;
      }

      self.existingRequest = function(appointment) {
        if (appointment.accessRequests && appointment.accessRequests.length > 0) {
          return true;
        }
        return false;
      }

      self.canCancelRequest = function(appointment) {
        const request = getActiveAccessRequest(appointment);
        if (request  != null) {
          const status = self.getAccessRequestStatus(request);
          if (status === 'Pending') {
            return true;
          }
        }
        return false;
      }


      self.getAccessRequestStatus = function(appointment) {
        if (appointment.accessRequests && appointment.accessRequests.length > 0) {
          const status = self.accessRequestStatuses.lookup(appointment.accessRequests[0].statusId);
          return status.status;
        }
        return null;
      }

      function getActiveAccessRequest(appointment) {
        for (const request of appointment.accessRequests) {
          if (request.revoked) {
            continue;
          }
          const status = self.getAccessRequestStatus(request);
          if (status === 'Pending' || status === 'Approved') {
            return request;
          }
        }
        return null;
      }

      self.getAccessRequestStatus =
        (request) => {
          if (self.accessRequestStatuses) {
            return self.accessRequestStatuses.lookup(request.statusId).status;
          }
          return null;
        }

      self.hasActiveAccessRequest = (appointment) => getActiveAccessRequest(appointment) !== null;

      self.hasAccessRequestPending = function(appointment) {
        const request = getActiveAccessRequest(appointment)
        return self.getAccessRequestStatus(request) === 'Pending';
      }

      self.accessRequestApproved = function(appointment) {
        const request = getActiveAccessRequest(appointment);
        return self.getAccessRequestStatus(request) === 'Approved';
      }

      self.hasAccess = function(appointment) {
        const request = getActiveAccessRequest(appointment);
        return self.getAccessRequestStatus(request) === 'Approved';
      }

      self.disableRequestButton = function(appointment) {
        if (appointment.officeId == OcrAuth.session.user.officeId || appointment.endDate || isOcrStaffMember()) {
          return true;
        }
        return false;
      }


      self.$onInit =
        () => $scope.$watch(
          () => angular.isDefined(self.caseForm),
          () => $scope.$watch(
            () => self.caseForm.$invalid === true && self.caseForm.$error.length > 0,
            () => $scope.$watch(
              () => angular.isDefined(self.caseForm.$error.required)
                && self.caseForm.$error.required.length,
              () => angular.forEach(self.caseForm.$error,
                (field) => angular.forEach(field, (errorField) => errorField.$setTouched()))
              , true)
            , true)
          , true);
    },
  ],
});
