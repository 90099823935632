const angularMessages = require("angular-messages");

angular.module('CaresApp').component('ocrAppointment', {
  templateUrl: 'components/ocr-appointment/ocr-appointment.template.html',
  bindings: {
    setParentPromise: '&onPromiseChanged',
  },
  controller: [
    '$scope',
    '$document',
    '$window',
    '$location',
    '$q',
    '$routeParams',
    '$mdDialog',
    '$mdToast',
    'AppointmentDataService',
    'AppointmentChildPartyService',
    'AppointmentOfficeAccessService',
    'AppointmentService',
    'CaseDataService',
    'CaseTypeAttorneyRoleService',
    'CaseTypeBillingAllocationService',
    'ChildPartyService',
    'OcrAuth',
    'OcrUtils',
    'OcrLookups',
    'SessionService',
    'AppointmentOfficeAccessRequestService',
    'UserService',
    'CaseService',
    function(
      $scope, $document, $window, $location, $q, $routeParams, $mdDialog,
      $mdToast, AppointmentDataService, AppointmentChildPartyService,
      AppointmentOfficeAccessService, AppointmentService, CaseDataService,
      CaseTypeAttorneyRoleService, CaseTypeBillingAllocationService, ChildPartyService, OcrAuth, OcrUtils,
      OcrLookups, SessionService, AppointmentOfficeAccessRequestService, UserService, CaseService,
    ) {
      const self = this;
      self.today = new Date();
      self.hasAnyPermission = OcrAuth.hasAnyPermission;
      self.checkPermission = OcrAuth.checkPermission;
      self.viewId = $routeParams.view;

      self.personaPermissions = self.permissions = self.caseTypes = OcrLookups.dummyList;

      OcrLookups.get('PersonaPermission').then(
        (personaPermissions) => self.personaPermissions = personaPermissions,
      );

      OcrLookups.get('Permission').then((permissions) => self.permissions = permissions);
      OcrLookups.get('CaseType').then((caseTypes) => self.caseTypes = caseTypes);

      const promises = [];

      self.query = {
        order: undefined,
        page: 1,
        limit: 20,
      }

      function fixFormPristine(form) {
        // Touching an input element inside a form that is inside another form causes the parent
        // form to be dirty. This function ensures that the dirtiness of the outer form is based
        // exclusively on the form's direct input elements.
        for (const prop in form) {
          const val = form[prop];
          if (angular.isObject(val) && val.$dirty) {
            form.$setDirty();
            return;
          }
        }
        form.$setPristine();
      }

      function childPartyFromAppointmentChildParty(acp) {
        if (!acp) {
          return {};
        }
        return {
          id: acp.childPartyId,
          firstName: acp.firstName,
          middleName: acp.middleName,
          lastName: acp.lastName,
          suffix: acp.suffix,
          dateOfBirth: acp.dateOfBirth,
          gender: acp.gender,
          raceEthnicity: acp.raceEthnicity,
          email: acp.email,
          phone: acp.phone,
          extension: acp.extension,
          address1: acp.address1,
          address2: acp.address2,
          city: acp.city,
          state: acp.state,
          zip: acp.zip,
        };
      }

      self.showExitButton =
        () => OcrAuth.checkPermission('APPOINTMENT_READ')
          && (!OcrAuth.hasAnyPermission(['APPOINTMENT_WRITE', 'APPOINTMENT_ALL', 'ADMIN_WRITE'])
            || self.hideEditing());

      self.showReopen = function() {
        if (!self.appointment || OcrAuth.session.user.officeId != self.appointment.officeId) {
          return false;
        }
        return self.isClosed();
      }

      self.disableReopen = function() {
        if (!self.case) {
          return true;
        }
        return self.submitting;
      }

      $scope.$on('$locationChangeStart',
        function(event, newUrl, oldUrl) {
          const newList = newUrl.split("#");
          const oldList = oldUrl.split("#");

          if (angular.isDefined(newList)
            && angular.isDefined(oldList)
            && newList[1] === oldList[1]
          ) {
            //$location.search("");
            return;
          }

          const removeQueryParams = function(url) {
            if (url) {
              const index = url.indexOf("?");
              if (index >= 0) {
                return url.substring(0, index);
              }
            }
            return url;
          };

          const newPath = $location.path();

          if (
            newPath === "/"
            || removeQueryParams(newUrl) === removeQueryParams(oldUrl)
          ) {
            //$location.search("");
            return;
          }

          fixFormPristine(self.appointment.form);
          if (self.childPartySaved) {
            self.appointment.form.$setDirty();
          }

          if (self.appointment.form.$pristine) {
            //$location.search("");
            return;
          }

          event.preventDefault();

          const message
            = "Are you sure that you want to leave this page without saving your changes?";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(function() {
            self.appointment.form.$setPristine();
            self.childPartySaved = false;
            self.clearChildPartyEdited();
            $location.search("");
            $location.path(newPath);
          }, function() {
            // do nothing
          });
        });

      $scope.$on("ReloadAttachmentsUp", () => $scope.$broadcast("ReloadAttachmentsDown", false));

      $window.onbeforeunload = () => (
        self.appointment.form.$pristine ? null : "Changes have been made.");

      $scope.$on("LoggingOut",
        () => {
          if (self.appointment.form.$pristine) {
            SessionService.signout();
            return;
          }

          const message
            = "Are you sure that you want to leave this page without saving your changes?";

          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Confirm Page Exit')
              .textContent(message)
              .ariaLabel(message)
              .ok('OK')
              .cancel('Cancel'),
          ).then(function() {
            SessionService.signout();
          });
        },
      );

      self.appointmentId = ($routeParams.appointmentId !== "create")
        ? $routeParams.appointmentId : undefined;

      self.appointment = {
        id: self.appointmentId,
        childParties: [],
        additionalUsers: [],
        involvedPersons: [],
      };

      self.listStyle = {};

      self.promise = null;
      $scope.$watch(() => self.promise, () => {
        self.setParentPromise()(self.promise);
      });

      self.updatePendingRequests = function() {
        if (self.appointmentId != undefined) {
          promises.push(AppointmentOfficeAccessRequestService.get({
            skip: (self.query.page - 1) * self.query.limit,
            limit: self.query.limit,
            appointmentId: self.appointmentId,
            requestStatusId: 1,
          }).$promise.then(function(response) {
            if (Object.keys(response.list).length == 0) {
              self.appointment.hasPendingRequest = false;
            }
            for (const appointment of response.list) {
              promises.push(UserService.get({
                id: appointment.appointedAttorneyId,
              }).$promise.then(function(response) {
                if (response.officeId == OcrAuth.session.user.officeId) {
                  self.appointment.hasPendingRequest = true;
                } else {
                  self.appointment.hasPendingRequest = false;
                }
                if (response == undefined) {
                  self.appointment.hasPendingRequest = false;
                }
                $scope.$apply();
              }));
            }
          }));
        }
      }

      self.updateMaxBillingAllocation = function() {
        if (
          self.appointment && self.appointment.financialProfile
          && (
            self.appointment.financialProfile.enteredNotInvoicedCents
            + self.appointment.financialProfile.invoicedNotPaidCents
            + self.appointment.financialProfile.paidCents
          ) > (self.appointment.appointmentBillingAllocationCents
            * self.appointment.allocationWarningPercent / 100.00)
          && OcrAuth.checkPermission("ALLOCATION_WARNINGS_READ") && self.appointment.officeId == OcrAuth.session.user.officeId
        ) {
          self.appointment.nearingMaxBillingAllocation = true;
        } else {
          self.appointment.nearingMaxBillingAllocation = false;
        }
      }

      self.setPromise = function(promise) {
        if (!self.promise && promise) {
          self.promise = promise.finally(() => self.promise = null);
        } else if (!self.promise) {
          self.promise = promise;
        } else {
          self.promise = $q.all([self.promise, promise]).finally(() => self.promise = null);
        }
      };

      self.attachmentAdded = function() {
        self.submitting = false;
        self.appointment.form.$setDirty();
      };

      self.updateData = function() {
          self.promise = AppointmentOfficeAccessService.get({
            appointmentId: self.appointmentId,
            officeId: OcrAuth.session.user.office.id,
          }).$promise.then(function(response) {
            if (response.total === 0) {
              self.officePersona = {
                id: 0,
              };
            } else {
              self.officePersona = response.list[0].persona;
            }
            return AppointmentDataService.get({
              id: self.appointmentId,
            }).$promise;
          }).then(
            function(response) {

              console.log("AppointmentDataService response: ", response);

              if (response.nickname) {
                OcrUtils.setPageTitle(`Appointment ${response.nickname}`);
              } else if (response.abbreviatedCaseNumber) {
                OcrUtils.setPageTitle(`Case ${response.abbreviatedCaseNumber}`);
              }
              if (self.appointment && self.appointment.form) {
                response.form = self.appointment.form;
              }
              self.appointment = response;
              self.appointment.currentAppointmentGroupId = self.appointment.appointmentGroupId;
              self.appointment.isValid = true;
              if (self.appointment.startDate) {
                self.appointment.startDate = new Date(self.appointment.startDate);
                self.appointment.earliestDate = new Date(self.appointment.startDate);
              }
              if (self.appointment.endDate) {
                self.appointment.endDate = new Date(self.appointment.endDate);
              }
              self.appointment.effectiveDate = new Date(self.appointment.effectiveDate);
              self.appointment.appointmentBillingAllocation = Math.trunc(
                self.appointment.appointmentBillingAllocationCents
                / 100.0);
              self.appointment.currentAppointmentBillingAllocationCents
                = self.appointment.appointmentBillingAllocationCents;
              self.appointment.currentAppointmentBillingPercent = self.appointment
                .appointmentBillingPercent;
              self.appointment.currentPercentEffectiveDate = self.appointment
                .effectiveDate;
              self.updateMaxBillingAllocation();
              self.updatePendingRequests();
              self.appointment.form.$setPristine();
              if (self.appointment.attorneyUserId) {
                self.appointment.user = {
                  id: response.attorneyUserId,
                  firstName: response.attorneyFirstName,
                  middleName: response.attorneyMiddleName,
                  lastName: response.attorneyLastName,
                  suffix: response.attorneySuffix,
                  attorneyRegistrationNumber: response.attorneyRegistrationNumber,
                  email: response.attorneyEmail,
                };
              }
              CaseTypeAttorneyRoleService.get({
                caseTypeId: self.appointment.caseTypeId
              }).$promise.then(
                function(response) {
                  self.appointment.caseTypeAttorneyRoles = OcrLookups.createLookupList(response.list);
                }
              );
              return CaseDataService.get({
                id: response.caseId,
              }).$promise;
            },
          ).catch(function(error) {
            if (error && error.data && error.data.status === 403) {
              const message
                = "You are not allowed to view this appointment.";
              $mdDialog.show(
                $mdDialog.confirm()
                  .parent(angular.element($document[0].querySelector(
                    '#popupContainer')))
                  .clickOutsideToClose(false)
                  .title('Forbidden')
                  .textContent(message)
                  .ariaLabel(message)
                  .ok('Ok'),
              ).then(function() {
                $location.path("/views/home");
              });
            } else {
              console.error("Unable to get appointment: %O", error);
            }
          }).then(function(response) {
            self.case = response;
            self.loading = false;
            self.promise = null;
          });
      }

      if (self.appointmentId) {
        OcrUtils.setPageTitle(`Appointment #${self.appointmentId}`);
        self.appointment.isNew = false;
        self.appointment.isValid = true;

        self.promise = AppointmentOfficeAccessService.get({
          appointmentId: self.appointmentId,
          officeId: OcrAuth.session.user.office.id,
        }).$promise.then(function(response) {
          if (response.total === 0) {
            self.officePersona = {
              id: 0,
            };
          } else {
            self.officePersona = response.list[0].persona;
          }
          return AppointmentDataService.get({
            id: self.appointmentId,
          }).$promise;
        }).then(
          function(response) {
            if (response.nickname) {
              OcrUtils.setPageTitle(`Appointment ${response.nickname}`);
            } else if (response.abbreviatedCaseNumber) {
              OcrUtils.setPageTitle(`Case ${response.abbreviatedCaseNumber}`);
            }
            if (self.appointment && self.appointment.form) {
              response.form = self.appointment.form;
            }
            self.appointment = response;
            self.appointment.currentAppointmentGroupId = self.appointment.appointmentGroupId;
            self.appointment.isValid = true;
            if (self.appointment.startDate) {
              self.appointment.startDate = new Date(self.appointment.startDate);
              self.appointment.earliestDate = new Date(self.appointment.startDate);
            }
            if (self.appointment.endDate) {
              self.appointment.endDate = new Date(self.appointment.endDate);
            }
            self.appointment.effectiveDate = new Date(self.appointment.effectiveDate);
            self.appointment.appointmentBillingAllocation = Math.trunc(
              self.appointment.appointmentBillingAllocationCents
              / 100.0);
            self.appointment.currentAppointmentBillingAllocationCents
              = self.appointment.appointmentBillingAllocationCents;
            self.appointment.currentAppointmentBillingPercent = self.appointment
              .appointmentBillingPercent;
            self.appointment.currentPercentEffectiveDate = self.appointment
              .effectiveDate;
            self.updateMaxBillingAllocation();
            self.updatePendingRequests();
            if (self.appointment.attorneyUserId) {
              self.appointment.user = {
                id: response.attorneyUserId,
                firstName: response.attorneyFirstName,
                middleName: response.attorneyMiddleName,
                lastName: response.attorneyLastName,
                suffix: response.attorneySuffix,
                attorneyRegistrationNumber: response.attorneyRegistrationNumber,
                email: response.attorneyEmail,
              };
            }
            CaseTypeAttorneyRoleService.get({
              caseTypeId: self.appointment.caseTypeId
            }).$promise.then(
              function(response) {
                self.appointment.caseTypeAttorneyRoles = OcrLookups.createLookupList(response.list);
              }
            );
            return CaseDataService.get({
              id: response.caseId,
            }).$promise;
          },
        ).catch(function(error) {
          if (error && error.data && error.data.status === 403) {
            const message
              = "You are not allowed to view this appointment.";
            $mdDialog.show(
              $mdDialog.confirm()
                .parent(angular.element($document[0].querySelector(
                  '#popupContainer')))
                .clickOutsideToClose(false)
                .title('Forbidden')
                .textContent(message)
                .ariaLabel(message)
                .ok('Ok'),
            ).then(function() {
              $location.path("/views/home");
            });
          } else {
            console.error("Unable to get appointment: %O", error);
          }
        }).then(function(response) {
          self.case = response;
          self.loading = false;
          self.promise = null;
        });
      } else {
        OcrUtils.setPageTitle('New Appointment');
        if ($routeParams.case) {
          self.appointment.caseId = Number($routeParams.case);
          CaseDataService.get({
            id: $routeParams.case,
          }).$promise
            .then(function(response) {
              if (self.appointment.form.$invalid) {
                angular.forEach(self.appointment.form.$error, function(
                  field) {
                  angular.forEach(field, function(errorField) {
                    errorField.$setTouched();
                  });
                });
              }
              self.case = response;
              self.appointment.fullCaseNumber = response.fullCaseNumber;
              self.appointment.judicialDistrict = response.judicialDistrictDescription;
              self.appointment.judicialDistrictId = response.judicialDistrictId;
              self.appointment.county = response.countyDescription;
              self.appointment.countyId = response.countyId;
              self.appointment.caseTypeDescription = response.caseTypeDescription;
              self.appointment.requirePlacement = response.requirePlacement;
              self.appointment.visitPrompt = response.visitPrompt;
              self.appointment.caseTypeId = response.caseTypeId;
              self.appointment.abbreviatedCaseNumber = response.abbreviatedCaseNumber;
              self.appointment.appointmentBillingPercent = 100;
              self.appointment.allocationWarningPercent
                = OcrLookups.getAppSetting("warningPercent").intValue;
              self.appointment.startDate = new Date();
              self.appointment.effectiveDate = new Date();
              self.appointment.earliestDate = new Date(response.dateOpened);
              self.appointment.caseDateOpened = new Date(response.dateOpened);
              //FIXME session data may not have arrived
              self.appointment.officeId = OcrAuth.session.user.office.id;
              self.appointment.officeName = OcrAuth.session.user.office.name;
              self.appointment.allocationWarningPercent = 80;
              self.appointment.financialProfile = {
                enteredNotInvoicedCents: 0,
                invoicedNotPaidCents: 0,
                paidCents: 0,
              };

              CaseTypeAttorneyRoleService.get({
                caseTypeId: self.appointment.caseTypeId
              }).$promise.then(
                function(response) {
                  self.appointment.caseTypeAttorneyRoles = OcrLookups.createLookupList(response.list);
                }
              );

              self.promise = CaseTypeBillingAllocationService.get({
                limit: 1000,
                caseTypeId: response.caseTypeId,
              }).$promise;
              return self.promise;
            })
            .catch(function(error) {
              console.error("Unable to get case info: %O", error);
            })
            .then(function(response) {
              self.appointment.appointmentBillingAllocationCents = 0;
              const curDate = new Date();

              for (let i = 0; i < response.list.length; i++) {
                if (Date.parse(response.list[i].startDate) <= curDate) {
                  self.appointment.appointmentBillingAllocationCents
                    = response.list[i].apptBillingAlloCents;
                  self.appointment.appointmentBillingAllocation = Math.trunc(
                    self.appointment.appointmentBillingAllocationCents
                    / 100.0);
                  break;
                }
              }
            })
            .catch(function(error) {
              console.error(
                "Unable to search case type billing allocations: %O",
                error);
            })
            .finally(() => {
              //TODO why will $watch not work otherwise?
              //// create a new object so that the $watch in sub-components works.
              self.appointment = Object.assign({}, self.appointment);
              self.appointment.isNew = true;
              self.promise = null;
            });
        }
        self.loading = false;
      }


      self.setTab = function(tabName) {
        if ((tabName !== 'profile' || $location.hash()) && angular.isDefined(self.officePersona)) {
          // so that going back in the browser will take us to the correct tab
          $location.hash(tabName);
        }
      };

      self.isTabActive =
        (tabName) => ($location.hash() === tabName && angular.isDefined(self.officePersona)) || ((!$location.hash() || angular.isUndefined(self.officePersona)) && tabName === "profile");

      self.addAttachment = () => $mdDialog.show({
        controller: 'AddAttachmentDialogController',
        templateUrl: 'tmpl/add-attachment-dialog.html',
        parent: angular.element($document[0].body),
        clickOutsideToClose: false,
        fullscreen: self.customFullscreen, // Only for -xs, -sm breakpoints.
      })
        .then(function(attachmentInfo) {
          self.appointment.attachments.push({
            date: new Date(),
            name: attachmentInfo.chosenFile,
            url: "#",
            keywords: attachmentInfo.keywords,
          });
        }, () => {});

      self.deleteAttachment = function() {
        const message
          = "Are you sure that you want to delete the selected attachment?";
        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Appointment Delete')
            .textContent(message)
            .ariaLabel(message)
            .ok('Delete Appointment')
            .cancel('Cancel'),
        ).then(() => {
          // ok
          const index = self.appointment.attachments.indexOf(self.selected[
            0]);
          self.appointment.attachments.splice(index, 1);
          self.selected.length = 0;
        });
      };

      self.exit = () => $location.path(`/views/${self.viewId}`);

      self.closeForm = function(noConfirm) {
        // only display confirmation message if form is dirty (has modified fields)
        if (noConfirm || self.appointment.form.$pristine) {
          if ($routeParams.case) {
            $location.path(`/case/cases/${$routeParams.case}`);
          } else {
            $location.path('/views/my_appointments');
          }
          return;
        }

        const message
          = "Are you sure that you want to close this form without saving your changes?";

        $mdDialog.show(
          $mdDialog.confirm()
            .parent(angular.element($document[0].querySelector(
              '#popupContainer')))
            .clickOutsideToClose(false)
            .title('Confirm Form Close')
            .textContent(message)
            .ariaLabel(message)
            .ok('Close Form')
            .cancel('Cancel'),
        ).then(function() {
          self.appointment.form.$setPristine();
          self.clearChildPartyEdited();
          if ($routeParams.case) {
            $location.path(`/case/cases/${$routeParams.case}`);
          } else {
            $location.path('/views/my_appointments');
          }
        });
      };

      self.clearChildPartyEdited = function() {
        for (const childParty of self.appointment.childParties) {
          childParty.edited = undefined;
        }
      };

      self.saveForm = function(close) {
        if (!self.appointment.childParties || self.appointment.childParties
          .length === 0) {
          const message = "An appointment requires at least one child party. "
            + "Please add a child party prior to saving.";
          $mdDialog.show(
            $mdDialog.confirm()
              .parent(angular.element($document[0].querySelector(
                '#popupContainer')))
              .clickOutsideToClose(false)
              .title('Unable to Save')
              .textContent(message)
              .ariaLabel(message)
              .ok('Ok'),
          ).then(() => {});
        } else {
          if(!self.appointmentId){
            if (self.case.statusId === 2) {
              self.case.dateClosed = null;
              self.case.statusId = 1;
              let saveFunction = CaseService.update;
              let saveArgs = [
                {
                  id: self.id,
                },
                self.case,
              ];
              saveFunction.apply(this, saveArgs).$promise.then(
                function(resp) {
                  self.case.id = resp.id;
                  self.id = resp.id;
                  $mdToast.show(
                    $mdToast.simple()
                      .textContent('Case saved.')
                      .position("bottom right"),
                  );
                })
            }
          }
          const continueSave = function() {
            self.submitting = true;
            let tempAppointmentId = undefined;
            $mdToast.show(
              $mdToast.simple()
                .textContent('Saving...')
                .position("bottom right"),
            );
            if (!self.appointment.id && !self.appointment.officeId) {
              self.appointment.officeId = OcrAuth.session.user.officeId;
            }
            self.appointment.appointmentBillingAllocationCents = Math.trunc(
              self.appointment.appointmentBillingAllocation * 100.0);
            const appointmentData = OcrUtils.makeVeryShallowCopy(self.appointment);

            if (appointmentData.appointmentGroupId === 0) {
              appointmentData.appointmentGroupId = undefined;
            }

            let saveFunction = null;
            let saveArgs = null;

            let compareStartDate;
            let compareEndDate;

            for (let i = 0; i < self.appointment.childParties.length; i++) {
              self.appointment.childParties[i].startDate = new Date(
                self.appointment.childParties[i].startDate);
              if (angular.isUndefined(compareStartDate)) {
                compareStartDate = new Date(self.appointment.childParties[i].startDate);
              } else if (compareStartDate > self.appointment.childParties[i].startDate) {
                compareStartDate = new Date(self.appointment.childParties[i].startDate);
              }
              if (self.appointment.endDate) {
                if (angular.isUndefined(compareEndDate)) {
                  compareEndDate = new Date(self.appointment.childParties[i].endDate);
                } else if (compareEndDate < self.appointment.childParties[i].endDate) {
                  compareEndDate = new Date(self.appointment.childParties[i].endDate);
                }
              }
            }

            appointmentData.startDate = new Date(compareStartDate);

            self.appointment.startDate = new Date(compareStartDate);
            self.appointment.earliestDate = new Date(compareStartDate);

            if (!angular.isUndefined(compareEndDate)) {
              self.appointment.endDate = new Date(compareEndDate);
            }
            self.appointment.currentAppointmentGroupId = self.appointment.appointmentGroupId;

            if (self.appointment.id) {
              saveFunction = AppointmentService.update;
              saveArgs = [
                {
                  id: self.appointment.id,
                },
                appointmentData,
              ];
            } else {
              appointmentData.effectiveDate = new Date(compareStartDate);
              self.appointment.effectiveDate = new Date(compareStartDate);
              saveFunction = AppointmentService.save;
              saveArgs = [appointmentData];
            }
            saveFunction.apply(this, saveArgs).$promise.then(
              function(resp) {
                self.childPartySaved = false;
                self.appointment.revisionId = resp.revisionId;

                self.updateMaxBillingAllocation();
                self.updatePendingRequests();
                tempAppointmentId = resp.id;
                self.appointment.id = resp.id;
                if (resp.nickname) {
                  OcrUtils.setPageTitle(`Appointment ${resp.nickname}`);
                } else if (resp.abbreviatedCaseNumber) {
                  OcrUtils.setPageTitle(`Case ${resp.abbreviatedCaseNumber}`);
                } else {
                  OcrUtils.setPageTitle(`Appointment #${self.appointmentId}`);
                }

                const newChildParties = [];
                const extantChildParties = [];

                for (const childParty of self.appointment.childParties) {
                  childParty.appointmentId = self.appointment.id;
                  const request = {
                    appointmentId: self.appointment.id,
                    childParty: childParty,
                  };
                  if (!childParty.id) {
                    newChildParties.push(request);
                    childParty.edited = true;
                  } else if (childParty.edited) {
                    extantChildParties.push(request);
                  }
                }

                const childPartyPromises = [];

                if (newChildParties.length > 0) {
                  childPartyPromises.push(
                    AppointmentChildPartyService.save({
                      parties: newChildParties,
                    }).$promise,
                  );
                }

                if (extantChildParties.length > 0) {
                  childPartyPromises.push(
                    AppointmentChildPartyService.update({
                      parties: extantChildParties,
                    }).$promise,
                  );
                }

                if (childPartyPromises.length === 0) {
                  $q.when();
                }

                return $q.all(childPartyPromises);
              },
            ).then(function(resp) {
              let results = [];
              for (let i = 0; i < resp.length; i++) {
                results = results.concat(resp[i].childParties);
              }

              for (let i = 0; i < results.length; i++) {
                const childParty = self.appointment.childParties[i];
                if (!childParty.id) {
                  childParty.id = results[i].id;
                  childParty.educationalSettings = results[i].educationalSettings;
                  childParty.placements = results[i].placements;
                }
              }
              if (self.appointment.currentAppointmentBillingAllocationCents
                !== self.appointment.appointmentBillingAllocationCents) {
                const entry = {
                  appointmentId: self.appointment.id,
                  userId: OcrAuth.session.user.id,
                  amountCents: self.appointment.appointmentBillingAllocationCents,
                  dateTime: new Date(),
                };

                if (angular.isDefined(self.appointment.abaHistory)) {
                  self.appointment.abaHistory.unshift(entry);
                }

                self.appointment.currentAppointmentBillingAllocationCents
                  = self.appointment.appointmentBillingAllocationCents;
              }

              const promises = [];
              for (const childParty of self.appointment.childParties) {
                if (childParty.edited) {
                  if (!childParty.id) {
                    const element = OcrUtils.getArrayElementById(resp,
                      "childPartyId", childParty.childPartyId);
                    if (element) {
                      childParty.id = element.id;
                    }
                  }

                  const actualChildParty = childPartyFromAppointmentChildParty(childParty);

                  promises.push(
                    ChildPartyService.update({
                      id: childParty.childPartyId,
                    }, actualChildParty).$promise,
                  );
                }
              }
              promises.push(AppointmentService.get({
                caseId: self.appointment.caseId,
              }).$promise.then(function(response) {
                let maxDate = new Date(0);
                let count = 0;
                response.list.forEach((appointment) => {
                  if (appointment.hasOwnProperty("endDate")) {
                    count += 1;
                    if (new Date(appointment.endDate) > new Date(maxDate)) {
                      maxDate = new Date(appointment.endDate);
                    }
                  }
                });
                if (count == response.count) {
                  promises.push(CaseDataService.get({
                    id: self.appointment.caseId,
                  }).$promise.then(function(response) {
                    response.action = "Close";
                    response.status = "Closed";
                    response.statusId = 2;
                    response.dateClosed = maxDate;
                    response.statusHistory.push({
                      date: self.appointment.endDate,
                      status: "Closed",
                    })
                    let saveFunction = CaseService.update;
                    let saveArgs = [
                      {
                        id: self.id,
                      },
                      response,
                    ];
                    saveFunction.apply(this, saveArgs);
                  }))
                }
              }));
              return $q.all(promises);
            }).then(function() {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Appointment saved.')
                  .position("bottom right"),
              );
              self.appointment.form.$setPristine();
              self.clearChildPartyEdited();
              if (close) {
                $location.path(`/views/${self.viewId}`);
                return;
              }
              self.submitting = false;
              self.appointment.isNew = false;
              self.appointmentId = tempAppointmentId;
              promises.push(AppointmentService.get({
                caseId: self.appointment.caseId,
              }).$promise.then(function(response){
                let minDate = new Date();
                for(const appointment of response.list) {
                  if(new Date(appointment.startDate) < new Date(minDate)) {
                    minDate = appointment.startDate;
                  }
                }
                promises.push(CaseDataService.get({
                  id: self.appointment.caseId,
                }).$promise.then(function(response){
                  if(response.statusId == 1) {
                    response.action = "Open";
                    response.status = "Open";
                    response.statusId = 1;
                    response.dateOpened = minDate;
                    let saveFunction = CaseService.update;
                    let saveArgs = [
                      {
                        id: self.id,
                      },
                      response,
                    ];
                    saveFunction.apply(this, saveArgs);
                  }
                }))
              }))
              self.appointment.updated = !self.appointment.updated; // force reload of activities
            }).catch((error) => {
              self.submitting = false;
              if (error && error.data && error.data.status === 401) {
                self.close(true);
              } else if (error && error.data && error.data.reason) {
                $mdToast.show(
                  $mdToast.simple()
                    .textContent(error.data.reason)
                    .position("bottom right"),
                );
              }
            });
          };

          let duplicateAttrny = false;
          if (self.appointment.isNew) {
            for (const appointment of self.case.appointments) {
              if (appointment.attorneyId === self.appointment.attorneyUserId) {
                if (!appointment.endDate || self.appointment.endDate > self.today) {
                  duplicateAttrny = true;
                  break;
                }
              }
            }
          }

          if (duplicateAttrny) {
            const message
              = "This attorney is already currently appointed to this case. Are you sure you want "
              + "to create multiple appointments for the same attorney (not recommended)? Note, to "
              + "add additional child(ren), click Cancel, then go to the existing appointment and "
              + "use the Add Child Party button instead.";
            $mdDialog.show(
              $mdDialog.confirm()
                .parent(angular.element($document[0].querySelector(
                  '#popupContainer')))
                .clickOutsideToClose(false)
                .title('Confirm Duplicate Attorney')
                .textContent(message)
                .ariaLabel(message)
                .ok('Save Appointment')
                .cancel('Cancel'),
            ).then(continueSave, () => {});
          } else {
            continueSave();
          }
        }
      };

      self.reopenForm = function() {
        $mdDialog.show({
          controller: 'ReopenAppointmentDialogController',
          templateUrl: 'components/ocr-appointment/dialogs/reopen-appointment-dialog.html',
          parent: angular.element($document[0].body),
          locals: {
            appointment: self.appointment,
            case: self.case,
          },
          bindToController: true,
          controllerAs: '$ctrl',
          clickOutsideToClose: false,
        })
          .then(function(childParties) {
            self.submitting = true;
            $mdToast.show(
              $mdToast.simple()
                .textContent('Saving...')
                .position("bottom right"),
            );

            const promises = [];
            for (const childParty of childParties) {
              childParty.endDate = undefined;
              childParty.endReasonId = undefined;
              childParty.appointmentId = self.appointment.id;
              const request = {
                appointmentId: self.appointment.id,
                childParty: childParty,
              };
              promises.push(
                AppointmentChildPartyService.update(request).$promise,
              );
            }

            self.appointment.endDate = undefined;

            const appointmentData = OcrUtils.makeVeryShallowCopy(self.appointment);

            promises.push(
              AppointmentService.update({
                id: self.appointment.id,
              }, appointmentData).$promise,
            );
            return $q.all(promises);
          })
          .then(function(resp) {
            $scope.$broadcast("updateChildParties");
            self.appointment.revisionId = resp[resp.length - 1].revisionId;
            self.submitting = false;
            $mdToast.show(
              $mdToast.simple()
                .textContent('Appointment saved.')
                .position("bottom right"),
            );
          }).catch(function(error) {
            self.submitting = false;
            if (error && error.data && error.data.status === 401) {
              self.close(true);
              return;
            }
            $mdToast.show(
              $mdToast.simple()
                .textContent(error.data.reason)
                .position("bottom right"),
            );
          });
      };

      self.saveCaseMinDate = function() {
          promises.push(CaseDataService.get({
            id: self.appointment.caseId,
          }).$promise.then(function(response){
            if(response.statusId == 1) {
              response.action = "Open";
              response.status = "Open";
              response.statusId = 1;
              let saveFunction = CaseService.update;
              let saveArgs = [
                {
                  id: self.id,
                },
                response,
              ];
              saveFunction.apply(this, saveArgs);
            }
          }))
      }
      self.saveFormGroup = function() {
        self.appointment.form.$setPristine();
        promises.push(AppointmentService.get({
          appointmentId: self.appointment.id,
        }).$promise.then(function(response) {
          self.appointmentInfo = response.list[0];
          let billingCents = (self.appointment.appointmentBillingAllocationCents/ 100);
          if (billingCents != self.appointment.appointmentBillingAllocation) {
            $mdDialog.show(
              $mdDialog.confirm()
                .parent(angular.element($document[0].querySelector(
                  '#popupContainer')))
                .clickOutsideToClose(false)
                .title('Changes to Appointment')
                .textContent("You have made changes to the appointment. Any changes that you made will be saved")
                .ok('Confirm')
                .cancel('Cancel')
            ).then(function() {
              self.saveForm();
            })
          } else if (self.appointmentInfo.nickname != self.appointment.nickname || self.appointmentInfo.attorneyUserId != self.appointment.user.id ||
            self.appointmentInfo.capacityId != self.appointment.appointmentCapacityId || self.appointmentInfo.billingAllocationCents != self.appointment.appointmentBillingAllocationCents ||
            self.appointmentInfo.allocationWarningPercent != self.appointment.allocationWarningPercent ||
            self.appointmentInfo.billingPercent != self.appointment.appointmentBillingPercent) {
            $mdDialog.show(
              $mdDialog.confirm()
                .parent(angular.element($document[0].querySelector(
                  '#popupContainer')))
                .clickOutsideToClose(false)
                .title('Changes to Appointment')
                .textContent("You have made changes to the appointment. Any changes that you made will be saved")
                .ok('Confirm')
                .cancel('Cancel')
            ).then(function() {
              self.saveForm();
            })
          }
          else {
            self.saveForm();
          }
        }));
      };

      self.styleButton = () => (self.appointment.childParties.length > 0 ? "md-primary" : "");

      self.getAppointmentStatus = function() {
        if (self.appointment) {
          if (self.appointment.endDate && self.appointment.endDate < self.today) {
            return "Closed";
          }
          return "Open";
        }
        return null;
      };

      self.hideTabs = function() {
        if ($routeParams.appointmentId === "create") {
          return false;
        } else if (angular.isUndefined(self.officePersona)) {
          return true;
        } else if (self.officePersona.id !== 0) {
          if (self.personaPermissions && self.permissions) {
            const permission = self.permissions.lookup('LIMITED_APPOINTMENT_ACCESS', 'name');

            if (angular.isUndefined(permission)) {
              console.error("LIMITED_APPOINTMENT_ACCESS permission does not exist. "
                + "Please create this permission and assign it to a persona");
              return true;
            }

            const personaPermissions = self.personaPermissions.lookup(
              permission.id, 'permissionId', true);

            for (const perm of personaPermissions) {
              if (perm.personaId === self.officePersona.id) {
                return true;
              }
            }
          }
        }
        return self.checkPermission('LIMITED_APPOINTMENT_ACCESS');
      };

      self.hideEditing = function() {
        if ($routeParams.appointmentId === "create") {
          return false;
        } else if (angular.isUndefined(self.officePersona)) {
          return true;
        } else if (self.officePersona.id !== 0) {
          if (self.personaPermissions && self.permissions) {
            const permission = self.permissions.lookup('APPOINTMENT_WRITE', 'name');

            if (angular.isUndefined(permission)) {
              console.error("APPOINTMENT_WRITE permission does not exist. "
                + "Please create this permission and assign it to a persona");
              return true;
            }

            const personaPermissions = self.personaPermissions.lookup(
              permission.id, 'permissionId', true);

            for (const perm of personaPermissions) {
              if (perm.personaId === self.officePersona.id) {
                return false;
              }
            }
          }
          return true;
        }
        return false;
      }

      self.isClosed = () => self.getAppointmentStatus() === "Closed";

      $scope.$watch(
        () => ((self.appointment.form) ? self.appointment.form.$invalid : null),
        () => {
          if (self.appointment.form) {
            self.appointment.isValid = !self.appointment.form.$invalid;
          }
        });

      $scope.$on('$routeChangeStart', () => $location.hash(""));

      $scope.$on('updateAppointmentGroup', () => {
        self.saveFormGroup();
      })

      $scope.$on('updatePendingRequests', () => {
        self.updatePendingRequests();
      });

      $scope.$on('childPartySaved', () => {
        self.childPartySaved = true;
      });
    },
  ],
});
